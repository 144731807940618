<template>
    <div class="page-wrapper body-padding-bottom">
        <section class="text-horizontal-scroll" v-if="contentIsLoaded">
            <div class="line line1 even">
                <h1 class="title" v-for="(mot, i) in content.ligne1" :key="i + 'l1'">{{ mot }}</h1>
            </div>
        </section>

        <section class="tab-wrap" v-if="pageIsLoaded">
            <div
                class="tab"
                :class="{ actif: actif === item.id, 'accent': item.id === 23229 }"
                v-for="(item, i) in globals.dates.data"
                :key="i + 'date'"
                @click.prevent="activate(item.id)"
            >
                <p class="title">{{ item.title }}</p>
            </div>
        </section>

        <!-- <div data-inview="fadeInUp" data-delay="200" class="center-button" v-if="pageIsLoaded && globals.footer.lien && globals.footer.lien.customText"><a :href="globals.footer.lienAsset[$route.meta.lang]" target="_blank" class="button-cta" :title="globals.footer.lien.customText"><p class="title">{{ globals.footer.lien.customText }}</p></a></div> -->

        <div v-if="pageIsLoaded">
            <div class="lineup">
                <transition name="fade" mode="out-in">
                    <div class="tab" v-if="actif == 23229">
                        <router-link
                            :to="artiste.page.jsonUrl"
                            class="artiste-wrap small"
                            data-inview-off="fadeIn"
                            data-delay="200"
                            v-for="artiste in programmation8"
                            :key="artiste.page.id + 'artiste-loop'"
                        >
                            <div class="img">
                                <div class="overlay"></div>
                                <img :src="artiste.imageSimple.url" :alt="artiste.imageSimple.titre" />
                            </div>
                            <footer>
                                <div class="top">
                                    <h2 class="title">{{ artiste.page.title }}</h2>
                                    <img
                                        src="@/assets/img/arrow-right-green.svg"
                                        alt="Spotify logo"
                                    />
                                </div>
                                <div class="bottom">
                                    <p class="text">{{ artiste.heures }}</p>
                                    <p class="text">{{ artiste.texte }}</p>
                                </div>
                            </footer>
                        </router-link>
                    </div>

                    <div class="tab" v-if="actif == 20818">
                        <router-link
                            :to="artiste.page.jsonUrl"
                            class="artiste-wrap"
                            :class="teteAffiche6.length === 1 ? 'full-width' : 'big'"
                            data-inview-off="fadeIn"
                            data-delay="200"
                            v-for="artiste in teteAffiche6"
                            :key="artiste.page.id + 'artiste-loop'"
                        >
                            <div class="img">
                                <div class="overlay"></div>
                                <img :src="artiste.imageSimple.url" :alt="artiste.imageSimple.titre" />
                            </div>
                            <footer>
                                <div class="top">
                                    <h2 class="title">{{ artiste.page.title }}</h2>
                                    <img
                                        src="@/assets/img/arrow-right-green.svg"
                                        alt="Spotify logo"
                                    />
                                </div>
                                <div class="bottom">
                                    <p class="text">{{ artiste.heures }}</p>
                                    <p class="text">{{ artiste.texte }}</p>
                                </div>
                            </footer>
                        </router-link>
                        <router-link
                            :to="artiste.page.jsonUrl"
                            class="artiste-wrap small"
                            data-inview-off="fadeIn"
                            data-delay="200"
                            v-for="artiste in programmation6"
                            :key="artiste.page.id + 'artiste-loop'"
                        >
                            <div class="img">
                                <div class="overlay"></div>
                                <img :src="artiste.imageSimple.url" :alt="artiste.imageSimple.titre" />
                            </div>
                            <footer>
                                <div class="top">
                                    <h2 class="title">{{ artiste.page.title }}</h2>
                                    <img
                                        src="@/assets/img/arrow-right-green.svg"
                                        alt="Spotify logo"
                                    />
                                </div>
                                <div class="bottom">
                                    <p class="text">{{ artiste.heures }}</p>
                                    <p class="text">{{ artiste.texte }}</p>
                                </div>
                            </footer>
                        </router-link>
                    </div>
                    <div class="tab" v-if="actif == 20817">
                        <router-link
                            :to="artiste.page.jsonUrl"
                            class="artiste-wrap"
                            :class="teteAffiche7.length === 1 ? 'full-width' : 'big'"
                            data-inview-off="fadeIn"
                            data-delay="200"
                            v-for="artiste in teteAffiche7"
                            :key="artiste.page.id + 'artiste-loop'"
                        >
                            <div class="img">
                                <div class="overlay"></div>
                                <img :src="artiste.imageSimple.url" :alt="artiste.imageSimple.titre" />
                            </div>
                            <footer>
                                <div class="top">
                                    <h2 class="title">{{ artiste.page.title }}</h2>
                                    <img
                                        src="@/assets/img/arrow-right-green.svg"
                                        alt="Spotify logo"
                                    />
                                </div>
                                <div class="bottom">
                                    <p class="text">{{ artiste.heures }}</p>
                                    <p class="text">{{ artiste.texte }}</p>
                                </div>
                            </footer>
                        </router-link>
                        <router-link
                            :to="artiste.page.jsonUrl"
                            class="artiste-wrap small"
                            data-inview-off="fadeIn"
                            data-delay="200"
                            v-for="artiste in programmation7"
                            :key="artiste.page.id + 'artiste-loop'"
                        >
                            <div class="img">
                                <div class="overlay"></div>
                                <img :src="artiste.imageSimple.url" :alt="artiste.imageSimple.titre" />
                            </div>
                            <footer>
                                <div class="top">
                                    <h2 class="title">{{ artiste.page.title }}</h2>
                                    <img
                                        src="@/assets/img/arrow-right-green.svg"
                                        alt="Spotify logo"
                                    />
                                </div>
                                <div class="bottom">
                                    <p class="text">{{ artiste.heures }}</p>
                                    <p class="text">{{ artiste.texte }}</p>
                                </div>
                            </footer>
                        </router-link>
                    </div>

                </transition>
            </div>
        </div>

        <section class="deal-wrap" v-if="pageIsLoaded && globals.mainNav.activerLaBilletterie">
            <div
                class="deal"
                :class="{ 'special': item.couleursOffres.value === 'jaune', 'full-width': globals.offres.data.length === 1, 'less-pad': globals.offres.data.length == 3 }"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in globals.offres.data"
                :key="i + 'offr'"
            >
                <div class="top">
                    <div class="left">
                        <p class="title name">{{ item.page.title }}</p>
                        <p class="title price">{{ item.prix }}</p>
                    </div>
                    <div class="right">
                        <img :src="item.imageSimple.url" :alt="item.imageSimple.titre" />
                    </div>
                </div>
                <ul class="details">
                    <li v-for="(line, j) in item.liste" :key="j + 'list'">
                        <img src="@/assets/img/small-arrow-right-orange.svg" alt="Arrow right" />
                        <p class="text">{{ line.texte }}</p>
                    </li>
                </ul>
                <div class="bottom" v-if="!item.complet">
                    <a :href="item.lienUrl" target="_blank" class="button-cta">
                        <p class="title">{{ item.lien.customText }}</p>
                    </a>
                </div>
                <div class="bottom" v-else>
                    <p class="title name">{{ $t('COMPLET') }}</p>
                </div>
            </div>

            <!-- <div data-inview="fadeInUp" data-delay="200" class="center-button" v-if="pageIsLoaded && globals.footer.lien && globals.footer.lien.customText"><a :href="globals.footer.lienAsset[$route.meta.lang]" target="_blank" class="button-cta" :title="globals.footer.lien.customText"><p class="title">{{ globals.footer.lien.customText }}</p></a></div> -->
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { gsap } from 'gsap'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Programmation',

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        // Page loaded with Data
                        initInViewAnimations()

                        this.actif = this.globals.dates.data[0].id

                        gsap.to('.line1', { x: '-150vw', duration: 80, ease: 'none', repeat: -1 })
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            actif: null,
        }
    },

    methods: {
        activate(i) {
            this.actif = i
        },
    },

    computed: {
        programmation() {
            if (!this.pageIsLoaded) {
                return []
            }

            const merge = {}

            for (const item of this.globals.artistes.data) {
                if (!merge[item.dateFestival.id]) {
                    merge[item.dateFestival.id] = []
                }
                merge[item.dateFestival.id].push(item)
            }

            return merge
        },
        teteAffiche6() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.content.teteDaffiche6) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                artistes.push(artiste)
            }

            return artistes
        },
        teteAffiche7() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.content.teteDaffiche7) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                artistes.push(artiste)
            }

            return artistes
        },
        programmation6() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.content.programmation6) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                artistes.push(artiste)
            }

            return artistes
        },
        programmation7() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.content.programmation7) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                artistes.push(artiste)
            }

            return artistes
        },
        programmation8 () {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.content.programmation8) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                artistes.push(artiste)
            }

            return artistes
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
